export default function SVGOnlyfans(): JSX.Element {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" width="20" height="20">
                <defs></defs>
                <rect style={{ fill: '#00aeef' }} width="400" height="400" rx="200" />
                <path
                    style={{ fill: '#fff', opacity: '0.8' }}
                    d="M156.25,125a87.5,87.5,0,1,0,87.5,87.5A87.53,87.53,0,0,0,156.25,125Zm0,113.75A26.25,26.25,0,1,1,182.5,212.5,26.21,26.21,0,0,1,156.25,238.75Z"
                />
                <path
                    className="c"
                    style={{ fill: '#fff' }}
                    d="M254.6,190.62c22.23,6.4,48.48,0,48.48,0-7.62,33.25-31.77,54.07-66.59,56.61A87.33,87.33,0,0,1,156.25,300l26.25-83.43c27-85.76,40.81-91.57,104.81-91.57h43.94C323.9,157.37,298.57,182.11,254.6,190.62Z"
                />
            </svg>
        </>
    );
}
