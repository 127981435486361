import SVGOnlyfans from '@components/svgs/svgOnlyfans';
import styles from '@styles/common-scss/card/_onlyfansCard.module.scss';
import Image from 'next/image';
import React, { useState } from 'react';
export default function FeaturedCard({ site }: any): JSX.Element {
    const [imgSrc, setImgSrc] = useState(`https://assets.rabbitsreviews.com/images/onlyfans/${site.thumb}`);

    return (
        <>
            <a
                href={site.tourlink}
                rel="nofollow no-referrer"
                target="_blank"
                title={'Visit ' + site.displayText ? site.displayText : site.name}
                className={styles.onlyfansCard}
                data-event-category="Onlyfans"
                data-opensite-path="onlyfans pages"
                data-event-label={site.displayText ? site.displayText : site.name}
                data-event-action="onlyfans-account"
                data-opensite-site={site.displayText ? site.displayText : site.name}>
                <div className={styles.container}>
                    {/* Thumbnail */}
                    <div className={`${styles.thumbnail}`}>
                        <div className={styles.onlyfansLogo}>
                            <SVGOnlyfans />
                        </div>
                        <div className={styles.image}>
                            <Image
                                unoptimized
                                alt={`Onlyfans Account thumbnail`}
                                className={styles.image}
                                src={imgSrc}
                                onError={() => {
                                    setImgSrc('https://assets.rabbitsreviews.com/images/rabbitslogo.png');
                                }}
                                layout="fill"
                                objectFit="cover"
                            />
                        </div>
                    </div>
                    {/* Thumbnail End */}
                    <div className={`${styles.content}`}>
                        <h3 className={styles.title}>{site.displayText ? site.displayText : site.name}</h3>
                    </div>
                </div>
            </a>
        </>
    );
}
